import { MainButton, SecondaryButton } from "components/buttons";
import { TextInput } from "components/inputs";
import { ArrowRight } from "lucide-react";
import { SyntheticEvent, useEffect, useState } from "react";

interface ILoginFormProps {
    loading: boolean
    navigation: (path: string) => void
    onSubmit: (email: string, password: string) => void
}

const LoginForm: React.FC<ILoginFormProps> = ({ loading, navigation, onSubmit }) => {
    const [formData, setFormData] = useState<{ email: string, password: string }>({ email: '', password: '' })
    const [errors, setErrors] = useState<{ email: string | undefined, password: string | undefined }>({ email: undefined, password: undefined })

    useEffect(() => {
        setErrors({ email: undefined, password: undefined })
    }, [formData])

    const connection = (e: SyntheticEvent) => {
        e.preventDefault()
        
        if (formData.email === '') {
            setErrors(prev => ({ email: "Veuillez renseigner votre adresse e-mail.", password: prev.password }))
            return
        }

        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)) {
            setErrors(prev => ({ email: "L'adresse e-mail n'est pas valide.", password: prev.password }))
            return
        }

        if (formData.password === '') {
            setErrors(prev => ({ email: prev.email, password: "Veuillez renseigner votre mot de passe." }))
            return
        }

        onSubmit(formData.email, formData.password)
    }

    return (
        <div className="flex flex-col gap-8 sm:gap-12">
            <div className="flex flex-col gap-4 sm:gap-3 w-full sm:max-w-[375px]">
                <TextInput type="email" label="E-mail" name="email" value={formData.email} placeholder="E-mail" onChange={(e: any) => setFormData(prev => ({ email: e.target.value, password: prev.password }))} isError={errors.email ? true : false} onEnterPress={(e: any) => connection(e)} />
                <div className="flex flex-col">
                    <div className="flex flex-row-reverse">
                        <a className="text-secondary cursor-pointer text-[14px] sm:text-base" onClick={() => navigation("/forgot-password")}>Mot de passe oublié ?</a>
                    </div>
                    <TextInput type="password" label="Mot de passe" name="password" value={formData.password} placeholder="Mot de passe" onChange={(e: any) => setFormData(prev => ({ email: prev.email, password: e.target.value }))} isError={errors.password ? true : false} onEnterPress={(e: any) => connection(e)} />
                </div>
                {
                    errors.email && <p className="text-red-600 text-[14px] sm:text-base font-bold">{errors.email}</p>
                }
                {
                    errors.password && <p className="text-red-600 text-[14px] sm:text-base font-bold">{errors.password}</p>
                }
            </div>
            <div className="hidden sm:flex flex-row justify-between items-center gap-2">
                <div className="flex flex-row gap-1">
                    <p className="text-base text-tertiary">Vous n'avez pas de compte ?</p>
                    <a className="text-base text-secondary cursor-pointer" onClick={() => navigation("/signup")}>S'inscrire</a>
                </div>
                {/* <button className="flex flex-row gap-1 items-center bg-secondary hover:bg-[#c75062] text-[#E2E5ED] p-2 rounded font-bold text-base" onClick={(e: any) => connection(e)}>
                    Se connecter
                    <ArrowRight size={20} color="#E2E5ED" />
                </button> */}
                <MainButton label="Se connecter" onClick={(e: SyntheticEvent) => connection(e)} isDisabled={loading} isLoading={loading} icon={{ position: 'right', icon: <ArrowRight size={20} color="#E2E5ED" /> }} />
            </div>
            <div className="sm:hidden flex flex-col gap-[18px] sm:gap-4">
                <MainButton label="Se connecter" onClick={(e: SyntheticEvent) => connection(e)} isDisabled={loading} isLoading={loading} />
                {/* <button className="w-full bg-secondary text-[#E2E5ED] text-[14px] font-bold px-2 py-3 rounded" onClick={(e: any) => connection(e)}>Se connecter</button> */}
                <SecondaryButton label="S'inscrire" onClick={() => navigation("/signup")} isDisabled={false} isLoading={false} />
            </div>
        </div>
    )
}

export default LoginForm;