import { ButtonDotLoader, SpinnerLoader } from "components/loaders";
import { MouseEventHandler } from "react";

interface SecondaryButtonProps {
    label: string;
    isDisabled: boolean;
    isLoading: boolean;
    onClick: MouseEventHandler;
    fullWidth?: boolean;
}

const SecondaryButton: React.FC<SecondaryButtonProps> = ({ label, isDisabled, isLoading, onClick, fullWidth }) => {
    const darkmode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

    const enabledStyles = { background: !darkmode ? '' : '', border: !darkmode ? '#DADADA' : '#747782', text: !darkmode ? '#232B35' : '#E2E5ED' };
    const disabledStyles = { background: !darkmode ? '' : '', border: !darkmode ? '#DADADA' : '#747782', text: !darkmode ? '#232B35' : '#E2E5ED' };
    
    return (
        <button 
            className="relative flex items-center justify-center w-full text-[14px] font-bold px-2 py-3 border rounded"
            disabled={isDisabled}
            onClick={isLoading ? () => {} : onClick}
            style={{
                backgroundColor: isDisabled ? disabledStyles.background : enabledStyles.background,
                borderColor: isDisabled ? disabledStyles.border : enabledStyles.border,
                color: isDisabled ? disabledStyles.text : enabledStyles.text
            }}
        >
            <span style={isLoading ? { visibility: 'hidden' } : {}}>{ label }</span>
            { isLoading && <div className="absolute inset-0 flex items-center justify-center">
                <SpinnerLoader size={20} color={{ light: "#E2E5ED", dark: "#E2E5ED" }} secondaryColor={{ light: "#9FA5AD", dark: "#9FA5AD" }} />
            </div> }
        </button>
    );
}

export default SecondaryButton;