import { useEffect, useState } from "react";
import { Location, NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import useAuthentification from "hooks/useAuthentification.hook";
import { connectionApi } from "api/user/connection.api";
import { TokenPayload } from "models/payload-token.model";
import { tokenToPayload } from "utils/token-to-payload.util";
import { refreshTokenApi } from "api/user/refresh-token.api";
import AuthenticationLayout from "components/layouts/authentication-layout.component";
import { SpinnerLoader } from "components/loaders";
import LoginForm from "feature/authentication/login-form.feature";

const LoginPage: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false)

    const [autoRefreshToken, setAutoRefreshToken] = useState<boolean>(false)

    const navigation: NavigateFunction = useNavigate()
    const localisation: Location = useLocation()
    const from = localisation.state?.from.pathname || '/dashboard/semaine'

    const { authentification, setAuthentification } = useAuthentification()

    useEffect(() => {
        if (authentification) {
            refreshTokenIfLogged()
        }
    }, [authentification])

    const refreshTokenIfLogged = async () => {
        setAutoRefreshToken(true)
        const token = await refreshTokenApi({ navigation: navigation, authentification: authentification, setAuthentification: setAuthentification })

        if (token && authentification && token.data) {
            const payload: TokenPayload = await tokenToPayload(token.data.accessToken);
            setAuthentification({ accessToken: token.data.accessToken, refreshToken: token.data.refreshToken, payload: payload })

            navigation(from, { replace: true })
        } else {
            if (setAuthentification) {
                setAuthentification(null)
            }
        }
        setAutoRefreshToken(false)
    }

    const connection = async (email: string, password: string) => {
        setLoading(true)
        const response = await connectionApi({ email: email, password: password }, { navigation, authentification, setAuthentification })
        setLoading(false)

        if (response.success && response.data) {
            const payload: TokenPayload = await tokenToPayload(response.data.accessToken)
            
            setAuthentification({...response.data, payload: payload})
            navigation(from, { replace: true })
        }
    }

    return (
        <AuthenticationLayout title="Se connecter à Plannify">
            <div className={autoRefreshToken ? "invisible" : ''}>
                <LoginForm loading={loading} navigation={navigation} onSubmit={connection} />
            </div>
            {
                autoRefreshToken && (
                    <div className="absolute inset-0 h-full flex items-center justify-center gap-4">
                        <span className="text-primary text-[14px] sm:text-base">Reconnexion en cours</span>
                        <SpinnerLoader />
                    </div>
                )
            }
        </AuthenticationLayout>
    )
}

export default LoginPage;