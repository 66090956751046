import { subscribeNotificationListApi } from "api/user/registration/subscribe-notification-list.api";
import { MainButton } from "components/buttons";
import { TextInput } from "components/inputs";
import { AlarmClockPlus, LucideArrowLeft } from "lucide-react";
import moment from "moment";
import 'moment/locale/fr';
import { SyntheticEvent, useEffect, useState } from "react";
import { NavigateFunction } from "react-router-dom";

interface IRegistrationLimitedProps {
    nextAvailability: Date | null;
    navigation: NavigateFunction;
}

const RegistrationLimited: React.FC<IRegistrationLimitedProps> = ({ nextAvailability, navigation }) => {
    const darkmode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    
    const [email, setEmail] = useState<string>('');
    const [errors, setErrors] = useState<{ email: string | undefined }>({ email: undefined })
    const [loading, setLoading] = useState<boolean>(false)
    const [subscriptionState, setSubscriptionState] = useState<boolean>(false)

    useEffect(() => {
        setErrors({ email: undefined })
    }, [email])

    const register = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (email === '') {
            setErrors({ email: "Veuillez renseigner votre adresse e-mail." })
            return
        }

        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
            setErrors({ email: "L'adresse e-mail n'est pas valide." })
            return
        }

        setLoading(true)
        const request = await subscribeNotificationListApi({ email: email }, { navigation: navigation, authentification: null, setAuthentification: null })
        setLoading(false)

        setSubscriptionState(request.success)
    }

    return (
        <div className="flex flex-col gap-8 sm:gap-12">
            {
                nextAvailability ? (
                    <div className="flex flex-col items-center">
                        <div className="flex flex-col gap-3 w-full sm:max-w-[650px] text-[14px] sm:text-base text-primary">
                            <p>Plannify est victime de son succès, actuellement plus aucune inscription n’est possible.</p>
                            <p>Cependant, nous avons une bonne nouvelle ! La réouverture des inscriptions est prévue pour le <strong>{ moment(nextAvailability).locale('fr').format("D MMMM YYYY") }</strong> à <strong>{ moment(nextAvailability).format("HH:mm") }</strong>.</p>
                        </div>
                    </div>
                ) : (
                    <div className="flex flex-col items-center justify-center gap-4 w-full">
                        {
                            subscriptionState ? (
                                <div className="w-full sm:max-w-[650px]">
                                    <p className="text-primary text-[14px] sm:text-base">Votre adresse e-mail a bien été enregistrée, vous serez averti dès que de nouveaux accès seront disponibles.</p>
                                </div>
                            ) : (
                                <>
                                    <div className="flex flex-col gap-1 w-full sm:max-w-[650px] text-primary">
                                        <p>Plannify est victime de son succès, actuellement plus aucune inscription n’est possible.</p>
                                        <p>Vous pouvez saisir votre adresse e-mail afin d’être averti lorsque de nouveaux accès seront disponible !</p>
                                    </div>
                                    <div className="flex flex-col gap-4 w-full sm:max-w-[375px]">
                                        <TextInput type="email" label="E-mail" name="email" placeholder="E-mail" value={email} onChange={(e: any) => setEmail(e.target.value)} isError={errors.email ? true : false} onEnterPress={() => {}} />
                                        {
                                            errors.email && <p className="text-red-600 text-[14px] sm:text-base font-bold">{errors.email}</p>
                                        }
                                        <div className="sm:hidden">
                                            <MainButton label="Être averti" onClick={(e: SyntheticEvent) => register(e)} isDisabled={loading} isLoading={loading} />
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </div>
                )
            }
            <div className="hidden sm:flex flex-row justify-between items-center gap-2">
                <div className="flex flex-row gap-1">
                    <p className="text-base text-tertiary">Vous avez déjà un compte ?</p>
                    <a className="text-base text-secondary cursor-pointer" onClick={() => navigation("/login")}>Se connecter</a>
                </div>
                {
                    !nextAvailability && !subscriptionState && (
                        <MainButton label="Être averti" onClick={(e: SyntheticEvent) => register(e)} isDisabled={loading} isLoading={loading} icon={{ position: 'right', icon: <AlarmClockPlus size={20} color="#E2E5ED" /> }} />
                    )
                }
            </div>
            <div className="sm:hidden flex flex-row items-center gap-1 text-primary">
                <LucideArrowLeft size={20} color={darkmode ? "#E2E5ED" : "#232B35"} />
                <p className="underline cursor-pointer text-[14px]" onClick={() => navigation("/login")}>Retourner à la page précédente</p>
            </div>
        </div>
    )
}

export default RegistrationLimited;