import { SpinnerLoader } from "components/loaders";
import { MouseEventHandler, ReactNode } from "react";

interface MainButtonProps {
    label: string;
    isDisabled: boolean;
    isLoading: boolean;
    onClick: MouseEventHandler;
    fullWidth?: boolean;
    colors?: {
        enabled: {
            background: string;
            backgroundHover: string;
            border: string;
            text: string;
        },
        disabled: {
            background: string;
            border: string;
            text: string;
        }
    },
    icon?: {
        position: 'left' | 'right';
        icon: ReactNode;
    }
}

const MainButton: React.FC<MainButtonProps> = ({ label, isDisabled, isLoading, onClick, fullWidth, colors, icon }) => {
    const darkmode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

    const enabledStyles = colors ? colors.enabled : { background: !darkmode ? 'var(--secondary-color)' : 'var(--secondary-color)', border: !darkmode ? '' : '', text: '#E2E5ED' };
    const disabledStyles = colors ? colors.disabled : { background: !darkmode ? 'var(--secondary-color)' : 'var(--secondary-color)', border: !darkmode ? '' : '', text: '#E2E5ED' };

    return (
        <>
            <button 
                className="hidden relative sm:flex hover:bg-[#c75062] p-2 rounded font-bold text-base"
                disabled={isDisabled}
                onClick={isLoading ? () => {} : onClick}
                style={{
                    backgroundColor: isDisabled ? disabledStyles.background : enabledStyles.background,
                    borderColor: isDisabled ? disabledStyles.border : enabledStyles.border,
                    color: isDisabled ? disabledStyles.text : enabledStyles.text
                }}
            >
                <div 
                    className={"flex flex-row gap-1 items-center"}
                    style={isLoading ? { visibility: 'hidden' } : {}}
                >
                    {
                        icon && icon.position === 'left' && icon.icon
                    }
                    { label }
                    {
                        icon && icon.position === 'right' && icon.icon
                    }
                </div>
                {
                    isLoading && <div className="absolute inset-0 flex items-center justify-center">
                        <SpinnerLoader size={20} color={{ light: "#E2E5ED", dark: "#E2E5ED" }} secondaryColor={{ light: "#9FA5AD", dark: "#9FA5AD" }} />
                    </div>
                }
            </button>
            <button 
                className="sm:hidden relative flex items-center justify-center w-full text-[14px] font-bold px-2 py-3 rounded"
                disabled={isDisabled}
                onClick={isLoading ? () => {} : onClick}
                style={{
                    backgroundColor: isDisabled ? disabledStyles.background : enabledStyles.background,
                    borderColor: isDisabled ? disabledStyles.border : enabledStyles.border,
                    color: isDisabled ? disabledStyles.text : enabledStyles.text
                }}
            >
                <span style={isLoading ? { visibility: 'hidden' } : {}}>{ label }</span>
                { isLoading && <div className="absolute inset-0 flex items-center justify-center">
                    <SpinnerLoader size={20} color={{ light: "#E2E5ED", dark: "#E2E5ED" }} secondaryColor={{ light: "#9FA5AD", dark: "#9FA5AD" }} />
                </div> }
            </button>
        </>
        // <button
        //     className={`transition-all delay-100 inline-block px-5 py-1.5 text-lg border-2 border-b-[5px] rounded-lg active:mt-[3px] active:border-b-[2px] translate-y-[3px] disabled:cursor-not-allowed disabled:active:mt-0 disabled:active:border-b-[5px] cursor-pointer ${fullWidth ? 'w-full' : ''}`}
        //     disabled={isDisabled}
        //     onClick={isLoading ? () => {} : onClick}
            // style={{
            //     backgroundColor: isDisabled ? disabledStyles.background : enabledStyles.background,
            //     borderColor: isDisabled ? disabledStyles.border : enabledStyles.border,
            //     color: isDisabled ? disabledStyles.text : enabledStyles.text,
            //     ...isLoading ? { margin: 0, padding: '0px 20px' } : {}
            // }}
        // >
        //     {isLoading ? <div className="flex justify-center content-center px-3 py-1"><ButtonDotLoader /></div> : label}
        // </button>
    );
}

export default MainButton;
