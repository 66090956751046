import { registrationApi } from "api/user/registration.api";
import { registrationStatusApi } from "api/user/registration/registration-status.api";
import AuthenticationLayout from "components/layouts/authentication-layout.component";
import { SpinnerLoader } from "components/loaders";
import RegistrationForm from "feature/authentication/registration-form.feature";
import RegistrationLimited from "feature/authentication/registration-limited.feature";
import { LightUser } from "models";
import { useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { toast } from "sonner";

const SignupPage: React.FC = () => {
    const [registration, setRegistration] = useState<{ loading: boolean, status: { date: Date | null } | null }>({ loading: true, status: null })
    const [loading, setLoading] = useState<boolean>(false)

    const [title, setTitle] = useState<string>("S'inscrire à Plannify")

    const navigation: NavigateFunction = useNavigate()

    useEffect(() => {
        getInscriptionState()
    }, [])

    const getInscriptionState = async () => {
        const inscriptionState = await registrationStatusApi({ navigation, authentification: null, setAuthentification: null })

        if (!inscriptionState.success)
            navigation('/login')
        else if (inscriptionState.data.data !== null)
            setRegistration({ loading: false, status: { date: inscriptionState.data.data.end_at !== null ? new Date(inscriptionState.data.data.end_at) : null } })
        else
            setRegistration({ loading: false, status: null })
    }

    const registrate = async (firstname: string, lastname: string, gender: string | null, email: string, password: string) => {
        setLoading(true)
        const response = await registrationApi({ firstname: firstname, lastname: lastname, gender: gender, email: email, password: password }, { navigation, authentification: null, setAuthentification: null } )
        setLoading(false)

        if (response.success) {
            toast.success("Inscription réussie.")
            navigation("/verification", { state: { user: LightUser.fromJSON(response.data) }})
        }
    }

    return (
        <AuthenticationLayout title={title}>
            {
                registration.loading && (
                    <div className="absolute inset-0 h-full flex items-center justify-center gap-4">
                        <span className="text-primary text-[14px] sm:text-base">Vérification de l'état des enregistrements en cours</span>
                        <SpinnerLoader />
                    </div>
                )
            }
            {
                registration.status && (
                    <RegistrationLimited nextAvailability={registration.status.date} navigation={navigation} />
                )
            }
            <div className={(registration.loading || registration.status) ? "invisible" : ""}>
                <RegistrationForm navigation={navigation} onSubmit={registrate} updateTitle={(e: string) => setTitle(e)} loading={loading} />
            </div>
        </AuthenticationLayout>
    )
}

export default SignupPage;