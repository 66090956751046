import { MainButton, SecondaryButton } from "components/buttons";
import PasswordStrength from "components/informations/password-strength.component";
import { Selector, TextInput } from "components/inputs";
import { ArrowRight } from "lucide-react";
import { SyntheticEvent, useEffect, useState } from "react";
import { NavigateFunction } from "react-router-dom";
import { toast } from "sonner";

interface IRegistrationFormProps {
    navigation: NavigateFunction,
    loading: boolean,
    updateTitle: (title: string) => void,
    onSubmit: (firstname: string, lastname: string, gender: string | null, email: string, password: string) => void
}

const RegistrationForm: React.FC<IRegistrationFormProps> = ({ navigation, loading, updateTitle, onSubmit }) => {
    const [formData, setFormData] = useState<{ firstName: string, lastName: string, gender: string | null, email: string, password: string, passwordConfirmation: string }>({ firstName: '', lastName: '', gender: '', email: '', password: '', passwordConfirmation: '' })
    const [errors, setErrors] = useState<{ firstName: string | undefined, lastName: string | undefined, email: string | undefined, password: string | undefined, passwordConfirmation: string | undefined }>({ firstName: undefined, lastName: undefined, email: undefined, password: undefined, passwordConfirmation: undefined })

    const [page, setPage] = useState<'info' | 'password'>('info')

    const [showTextLimitation, setShowTextLimitation] = useState<boolean>(false)

    useEffect(() => {
        setErrors({ firstName: undefined, lastName: undefined, email: undefined, password: undefined, passwordConfirmation: undefined })
    }, [formData])

    useEffect(() => {
        if (showTextLimitation && formData.firstName.length <= 100 && formData.lastName.length <= 100 && formData.email.length <= 200) {
            setShowTextLimitation(false)
        }
    }, [formData])

    const generateStrongPassword = async (e: SyntheticEvent) => {
        e.preventDefault()

        const letters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
        const numbers = '0123456789'
        const specials = '!@#$%^&*_'

        let password: string = ''

        do {
            password = Array.from({ length: Math.round(Math.random() * 6 + 12) }, () => {
                const type = Math.floor(Math.random() * 3)
                switch (type) {
                    case 0:
                        return letters[Math.floor(Math.random() * letters.length)]
                    case 1:
                        return numbers[Math.floor(Math.random() * numbers.length)]
                    case 2:
                        return specials[Math.floor(Math.random() * specials.length)]
                }
            }).join('')
        } while (password.length < 10 || !/[A-Z]/.test(password) || !/[a-z]/.test(password) || !/[0-9]/.test(password) || !/[^a-zA-Z0-9]/.test(password))
        
        setFormData(prev => ({...prev, password: password, passwordConfirmation: password }))

        try {
            await navigator.clipboard.writeText(password);
            toast.info("Le mot de passe a été copié dans le presse-papiers.")
        } catch (error) {
            console.error("Erreur lors de la copie du mot de passe : ", error);
        }
    }

    const checkRegistration = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (formData.firstName === '') {
            setErrors(prev => ({...prev, firstName: "Veuillez renseigner votre prénom."}))
            return
        }
        if (formData.firstName.length > 100) {
            setShowTextLimitation(true)
            setErrors(prev => ({...prev, firstName: "Le prénom est trop long."}))
            return
        }
        if (formData.lastName === '') {
            setErrors(prev => ({...prev, lastName: "Veuillez renseigner votre nom."}))
            return
        }
        if (formData.lastName.length > 100) {
            setShowTextLimitation(true)
            setErrors(prev => ({...prev, lastName: "Le nom est trop long."}))
            return
        }
        if (formData.email === '') {
            setErrors(prev => ({...prev, email: "Veuillez renseigner votre adresse e-mail."}))
            return
        }
        if (formData.email.length > 200) {
            setShowTextLimitation(true)
            setErrors(prev => ({...prev, email: "L'adresse e-mail est trop longue."}))
            return
        }
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)) {
            setErrors(prev => ({...prev, email: "L'adresse e-mail n'est pas valide."}))
            return
        }
        if (formData.password === '') {
            setErrors(prev => ({...prev, password: "Veuillez renseigner un mot de passe."}))
            return
        }
        if (formData.passwordConfirmation === '') {
            setErrors(prev => ({...prev, passwordConfirmation: "Veuillez confirmer votre mot de passe."}))
            return
        }
        if (formData.password.length < 10 || !/[A-Z]/.test(formData.password) || !/[a-z]/.test(formData.password) || !/[0-9]/.test(formData.password) || !/[^a-zA-Z0-9]/.test(formData.password)) {
            setErrors(prev => ({...prev, password: "Le mot de passe doit respecter les exigences de sécurité."}))
            return
        }
        if (formData.password !== formData.passwordConfirmation) {
            setErrors(prev => ({...prev, passwordConfirmation: "Les mots de passe ne correspondent pas."}))
            return
        }

        onSubmit(formData.firstName, formData.lastName, formData.gender, formData.email, formData.password)
    }

    const switchPage = (e: SyntheticEvent) => {
        e.preventDefault()

        if (page === 'info') {
            if (formData.firstName === '') {
                setErrors(prev => ({...prev, firstName: "Veuillez renseigner votre prénom."}))
                return
            }
            if (formData.firstName.length > 100) {
                setShowTextLimitation(true)
                setErrors(prev => ({...prev, firstName: "Le prénom est trop long."}))
                return
            }
            if (formData.lastName === '') {
                setErrors(prev => ({...prev, lastName: "Veuillez renseigner votre nom."}))
                return
            }
            if (formData.lastName.length > 100) {
                setShowTextLimitation(true)
                setErrors(prev => ({...prev, lastName: "Le nom est trop long."}))
                return
            }
            if (formData.email === '') {
                setErrors(prev => ({...prev, email: "Veuillez renseigner votre adresse e-mail."}))
                return
            }
            if (formData.email.length > 200) {
                setShowTextLimitation(true)
                setErrors(prev => ({...prev, email: "L'adresse e-mail est trop longue."}))
                return
            }
            if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)) {
                setErrors(prev => ({...prev, email: "L'adresse e-mail n'est pas valide."}))
                return
            }
            setPage('password')
            updateTitle(`Vous y êtes presque ${formData.firstName} 😄`)
        } else {
            setPage('info')
            updateTitle("S'inscrire à Plannify")
        }
    }

    return (
        <div className="flex flex-col gap-8 sm:gap-12">
            <div className="flex flex-col gap-4 sm:gap-3 w-full">
                <div className="flex flex-row gap-8">
                    <div className={"flex flex-col gap-4 sm:gap-3 w-full " + (page === 'info' ? 'flex' : 'hidden sm:flex')}>
                        <div className="flex flex-row gap-3">
                            <TextInput label="Prénom" type="text" placeholder="Prénom" name="firstname" value={formData.firstName} maxLength={showTextLimitation ? 100 : undefined} onChange={(e: any) => setFormData(prev => ({...prev, firstName: e.target.value }))} isError={errors.firstName ? true : false} />
                            <TextInput label="Nom" type="text" placeholder="Nom" name="lastname" value={formData.lastName} maxLength={showTextLimitation ? 100 : undefined} onChange={(e: any) => setFormData(prev => ({...prev, lastName: e.target.value }))} isError={errors.lastName ? true : false} />
                        </div>
                        <Selector options={[{ value: "M", label: "Monsieur" }, { value: "Mlle", label: "Mademoiselle" }, { value: "Mme", label: "Madame" }, { value: "any", label: "Aucun" }]} label="Genre" isError={false} defaultValue={formData.gender} onChange={(e: string) => setFormData(prev => ({...prev, gender: e }))} />
                        <TextInput label="E-mail" type="email" placeholder="E-mail" name="email" value={formData.email} maxLength={showTextLimitation ? 200 : undefined} onChange={(e: any) => setFormData(prev => ({...prev, email: e.target.value }))} isError={errors.email ? true : false} />
                    </div>
                    <div className={"flex flex-col gap-4 w-full " + (page === 'password' ? 'flex' : 'hidden sm:flex')}>
                        <div className="flex flex-col gap-2">
                            <div className="flex flex-col gap-4 sm:gap-3">
                                <TextInput label="Mot de passe" type="password" placeholder="Mot de passe" name="password" value={formData.password} onChange={(e: any) => setFormData(prev => ({...prev, password: e.target.value }))} isError={errors.password ? true : false} />
                                <TextInput label="Confirmation du mot de passe" type="password" placeholder="Confirmation du mot de passe" name="password" value={formData.passwordConfirmation} onChange={(e: any) => setFormData(prev => ({...prev, passwordConfirmation: e.target.value }))} isError={errors.passwordConfirmation ? true : false} />
                            </div>
                            <div>
                                <a className="inline-block text-secondary cursor-pointer text-[14px] sm:text-base" onClick={(e: SyntheticEvent) => generateStrongPassword(e)}>Générer un mot de passe robuste</a>
                            </div>
                        </div>
                        <PasswordStrength password={formData.password} />
                    </div>
                </div>
                {
                    (errors.firstName || errors.lastName || errors.email || errors.password || errors.passwordConfirmation) && (
                        <p className="text-red-600 text-[14px] sm:text-base font-bold">{errors.firstName ?? errors.lastName ?? errors.email ?? errors.password ?? errors.passwordConfirmation}</p>
                    )
                }
            </div>
            {
                page === 'info' ? (
                    <div className="sm:hidden flex flex-col justify-between items-center gap-4">
                        <MainButton label="Continuer l’inscription" onClick={switchPage} isDisabled={loading} isLoading={loading} />
                        <SecondaryButton label="Se connecter" onClick={() => navigation("/login")} isDisabled={false} isLoading={false} />
                    </div>
                ) : (
                    <div className="sm:hidden flex flex-row justify-between items-center gap-4">
                        <SecondaryButton label="Page précédente" onClick={switchPage} isDisabled={false} isLoading={false} />
                        <MainButton label="S'inscrire" onClick={checkRegistration} isDisabled={loading} isLoading={loading} />
                    </div>
                )
            }
            <div className="hidden sm:flex flex-row justify-between items-center gap-2">
                <div className="flex flex-row gap-1">
                    <p className="text-base text-tertiary">Vous avez déjà un compte ?</p>
                    <a className="text-base text-secondary cursor-pointer" onClick={() => navigation("/login")}>Se connecter</a>
                </div>
                <MainButton label="S'inscrire" onClick={checkRegistration} isDisabled={loading} isLoading={loading} icon={{ position: 'right', icon: <ArrowRight size={20} color="#E2E5ED" /> }} />
            </div>
        </div>
    )
}

export default RegistrationForm;