import { ReactNode } from "react";

interface IAuthenticationLayout {
    title: string;
    children: ReactNode;
}

const AuthenticationLayout: React.FC<IAuthenticationLayout> = ({ title, children }) => {
    const metaTag = document.querySelector('meta[name="theme-color"]');
    if (metaTag) {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)
            metaTag.setAttribute('content', '#0F1013');
        else
            metaTag.setAttribute('content', '#F6F8FB');
    }

    return (
        <div className="w-full h-screen flex justify-center items-center p-3.5">
            <div className="flex flex-col gap-12 p-[26px] sm:p-[30px] w-full max-w-[375px] sm:max-w-[850px] rounded border border-[#DADADA] dark:border-[#191D29] relative overflow-hidden">
                <div className="grid grid-cols-4 absolute -top-9 z-0">
                    <div className="rounded-full w-[185px] h-[185px] bg-gradient-to-r from-[#BE5A69] via-[#C63C51] to-[#962536] opacity-15 blur-3xl z-10"></div>
                    <div className="rounded-full w-[139px] h-[139px] bg-[#B66571] opacity-15 blur-3xl z-10"></div>
                    <div className="rounded-full w-[185px] h-[185px] bg-[#B66571] opacity-15 blur-3xl z-10"></div>
                    <div className="rounded-full w-[278px] h-[278px] bg-[#BC3D6F] opacity-10 blur-3xl z-10"></div>
                </div>
                <div className="flex flex-col gap-3">
                    <h3 className="text-[12px] sm:text-[14px] text-tertiary font-bold">PLANNIFY {process.env.REACT_APP_VERSION}</h3>
                    <h1 className="text-[22px] sm:text-[32px] text-primary font-bold">{ title }</h1>
                </div>
                <div className="relative">
                    { children }
                </div>
            </div>
        </div>
    )
}

export default AuthenticationLayout;