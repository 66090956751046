import { sendForgotPasswordMailApi } from "api/user/forgot-password.api";
import { useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import AuthenticationLayout from "components/layouts/authentication-layout.component";
import ForgotPasswordForm from "feature/authentication/forgot-password-form.feature";
import { ArrowLeft } from "lucide-react";

const ForgotPasswordPage: React.FC = () => {
    const darkmode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

    const [loading, setLoading] = useState<boolean>(false)
    const [mailSent, setMailSent] = useState<boolean>(false)

    const navigation: NavigateFunction = useNavigate()

    const sendEmailResetPassword = async (email: string) => {
        setLoading(true)
        const result = await sendForgotPasswordMailApi({ email: email }, { navigation, authentification: null, setAuthentification: null })
        setLoading(false)

        if (result.success) {
            setMailSent(true)
        }
    }

    return (
        <AuthenticationLayout title="Réinitialiser le mot de passe">
            {
                mailSent ? (
                    <div className="flex flex-col gap-8 sm:gap-12">
                        <div className="flex justify-center w-full">
                            <p className="w-fit sm:max-w-[650px] text-primary">Un mail vous a bien été envoyé ! Pensez à vérifier vos spams.</p>
                        </div>
                        <div className="sm:hidden flex flex-row items-center gap-1 text-primary">
                            <ArrowLeft size={20} color={darkmode ? "#E2E5ED" : "#232B35"} />
                            <p className="underline cursor-pointer text-[14px]" onClick={() => navigation("/login")}>Retourner à la page de connexion</p>
                        </div>
                        <div className="hidden sm:flex flex-row gap-1">
                            <p className="text-base text-tertiary">Vous souhaitez retourner à la page d’authentification ?</p>
                            <a className="text-base text-secondary cursor-pointer" onClick={() => navigation("/login")}>Se connecter</a>
                        </div>
                    </div>
                ) : (
                    <ForgotPasswordForm loading={loading} onSubmit={sendEmailResetPassword} navigation={navigation} />
                )
            }
        </AuthenticationLayout>
    )
}

export default ForgotPasswordPage;