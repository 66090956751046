import { Eye, EyeOff } from "lucide-react";
import { ChangeEventHandler, useState } from "react";

interface ITextInputProps {
    type: string,
    label: string | undefined,
    name?: string,
    value: string,
    placeholder: string,
    maxLength?: number,
    onChange: ChangeEventHandler,
    isError: boolean,
    onEnterPress?: (e: any) => void
}

const TextInput: React.FC<ITextInputProps> = ({ type, label, name, value, placeholder, maxLength, onChange, isError, onEnterPress }) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const isDarkmode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

    return (
        <div className={"relative w-full" + (value !== "" || label === undefined ? " mt-0" : " mt-2")}>
            <fieldset className={"relative flex flex-col gap-1 border border-[#DADADA] dark:border-[#191D29] bg-[#F6F7F8] dark:bg-[#111319] rounded-md w-full sm:max-w-[375px] px-2 py-3 sm:py-2 focus-within:ring-[#C63C51]/25 focus-within:ring-offset-[#C63C51] focus-within:ring-offset-1 focus-within:ring-4" + (value !== "" && label !== undefined ? " pt-1 sm:pt-0" : "") + (maxLength != null && maxLength > 0 ? ' pb-1' : '')} style={isError ? { borderColor: "#F73131" } : {}}>
                <legend
                    className="text-gray-500 text-xs px-1 w-fit"
                    style={{
                        display: value !== "" && label !== undefined ? "block" : "none",
                    }}
                >{label}</legend>
                <div className="flex flex-row justify-between items-center gap-2">
                    {
                        type === 'password' ? <>
                            <input type={showPassword ? 'text' : 'password'} name={name || ''} maxLength={maxLength} spellCheck="false" value={value} placeholder={placeholder} onChange={onChange} className={"relative w-full border-0 bg-transparent focus:outline-none text-[14px] sm:text-base " + (isError ? 'text-[#FE0000]' : 'text-primary')} onKeyDown={onEnterPress ? ((e: any) => e.key === 'Enter' ? onEnterPress(e) : {}) : () => {}} />
                            {
                                showPassword ? <Eye className="cursor-pointer" color={isDarkmode ? "#E2E5ED" : "#232B35"} onClick={() => setShowPassword(false)} /> : <EyeOff className="cursor-pointer" color={isDarkmode ? "#E2E5ED" : "#232B35"} onClick={() => setShowPassword(true)} />
                            }
                        </> : <input type={type} name={name || ''} value={value} maxLength={maxLength} spellCheck="false" placeholder={placeholder} onChange={onChange} className={"relative w-full border-0 bg-transparent focus:outline-none text-[14px] sm:text-base " + (isError ? 'text-[#FE0000]' : 'text-primary')} onKeyDown={onEnterPress ? ((e: any) => e.key === 'Enter' ? onEnterPress(e) : {}) : () => {}} />
                    }
                </div>
                { maxLength != null && maxLength > 0 && (
                    <p className="text-xs text-right text-primary">{value.length}<span className="text-[#9FA5AD] dark:text-[#65687A]">/{maxLength}</span></p>
                    )
                }
            </fieldset>
        </div>
    );
}

export default TextInput;