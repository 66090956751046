interface ISelectorProps {
    options: {
        value: string,
        label: string
    }[],
    label: string | undefined,
    isError: boolean,
    defaultValue: string | null,
    onChange: (gender: string) => void,
    fullWidth?: boolean
}

const Selector: React.FC<ISelectorProps> = ({ options, label, isError, defaultValue, onChange, fullWidth }) => {
    return (
        <div className="relative w-full">
            <fieldset className={"border border-[#DADADA] dark:border-[#191D29] bg-[#F6F7F8] dark:bg-[#111319] rounded-md w-full sm:max-w-[375px] px-2 py-3 sm:py-2 focus-within:ring-[#C63C51]/25 focus-within:ring-offset-[#C63C51] focus-within:ring-offset-1 focus-within:ring-4" + (label !== undefined ? " pt-1 sm:pt-0" : "") + (fullWidth ? " w-full" : "")} style={isError ? { borderColor: "#F73131" } : {}}>
                {label !== undefined && <legend className="text-gray-500 text-xs text-left px-1 group-focus-within:text-secondary group-focus-within:translate-y-[-4px]">{label}</legend>}
                <select onChange={(e: any) => onChange(e.target.value)} value={defaultValue ?? options[0].value} className={"relative w-full text-[14px] sm:text-base cursor-pointer border-0 bg-transparent font-['Sansation'] focus:outline-none " + (isError ? 'text-[#F73131]' : 'text-primary')}>
                    {options.map(option => (
                        <option value={option.value} key={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </fieldset>
        </div>
    )
}

export default Selector