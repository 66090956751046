import { MainButton } from "components/buttons";
import { TextInput } from "components/inputs";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { SyntheticEvent, useEffect, useState } from "react";
import { NavigateFunction } from "react-router-dom";

interface IForgotPasswordFormProps {
    loading: boolean;
    onSubmit: (email: string) => void;
    navigation: NavigateFunction;
}

const ForgotPasswordForm: React.FC<IForgotPasswordFormProps> = ({ loading, onSubmit, navigation }) => {
    const darkmode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

    const [formData, setFormData] = useState<{ email: string }>({ email: '' })
    const [errors, setErrors] = useState<{ email: string | undefined }>({ email: undefined })

    useEffect(() => {
        setErrors({ email: undefined })
    }, [formData])

    const sendMail = (e: SyntheticEvent) => {
        e.preventDefault();

        if (formData.email === '') {
            setErrors({ email: "Veuillez renseigner votre adresse e-mail." })
            return
        }
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)) {
            setErrors({ email: "L'adresse e-mail n'est pas valide." })
            return
        }

        onSubmit(formData.email)
    }

    return (
        <div className="flex flex-col gap-8 sm:gap-12">
            <div className="flex flex-col items-center justify-center gap-4 w-full">
                <p className="w-full sm:max-w-[650px] text-primary">Veuillez indiquer l'adresse e-mail que vous avez renseigné lors de votre inscription.</p>
                <div className="flex flex-col gap-8 w-full sm:max-w-[375px]">
                    <div className="flex flex-col gap-2">
                        <TextInput type="email" label="E-mail" name="email" value={formData.email} placeholder="E-mail" onChange={(e: any) => setFormData({ email: e.target.value })} onEnterPress={sendMail} isError={errors.email ? true : false} />
                        {
                            errors.email && <p className="text-red-600 text-[14px] sm:text-base font-bold">{errors.email}</p>
                        }
                    </div>
                    <div className="sm:hidden">
                        <MainButton label="Envoyer" onClick={sendMail} isDisabled={loading} isLoading={loading} />
                    </div>
                    <div className="sm:hidden flex flex-row items-center gap-1 text-primary">
                        <ArrowLeft size={20} color={darkmode ? "#E2E5ED" : "#232B35"} />
                        <p className="underline cursor-pointer text-[14px]" onClick={() => navigation("/login")}>Retourner à la page précédente</p>
                    </div>
                </div>
            </div>
            <div className="hidden sm:flex flex-row justify-between items-center gap-2">
                <div className="flex flex-row gap-1">
                    <p className="text-base text-tertiary">Vous souhaitez retourner à la page d’authentification ?</p>
                    <a className="text-base text-secondary cursor-pointer" onClick={() => navigation("/login")}>Se connecter</a>
                </div>
                {/* <button className="flex flex-row gap-1 items-center bg-secondary hover:bg-[#c75062] text-[#E2E5ED] p-2 rounded font-bold text-base" onClick={(e: any) => connection(e)}>
                    Se connecter
                    <ArrowRight size={20} color="#E2E5ED" />
                </button> */}
                <MainButton label="Envoyer" onClick={sendMail} isDisabled={loading} isLoading={loading} icon={{ position: 'right', icon: <ArrowRight size={20} color="#E2E5ED" /> }} />
            </div>
        </div>
    )
}

export default ForgotPasswordForm;