import { SpinnerCircular } from "spinners-react";

interface ISpinnerLoaderProps {
    size?: number;
    thickness?: number;
    speed?: number;
    color?: {
        light: string,
        dark: string
    };
    secondaryColor?: {
        light: string,
        dark: string
    };
}

const SpinnerLoader: React.FC<ISpinnerLoaderProps> = ({ size, thickness, speed, color, secondaryColor }) => {
    const isDarkmode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

    return (
        <SpinnerCircular
            size={size ?? 24}
            className="p-0 m-0"
            thickness={thickness ?? 200}
            speed={speed ?? 140}
            color={color ? (isDarkmode ? color.dark : color.light) : "#C63C51"}
            secondaryColor={secondaryColor ? (isDarkmode ? secondaryColor.dark : secondaryColor.light) : "#DADADA"}
        />
    )
}

export default SpinnerLoader