import { useEffect, useState } from "react";

interface IPasswordStrengthProps {
    password: string
}

const PasswordStrength: React.FC<IPasswordStrengthProps> = ({ password }) => {
    const [indicator, setIndicator] = useState<{ width: number, text: string, color: string }>({ width: 0, text: 'Veuillez saisir un mot de passe', color: '#7D7D7D' })

    const checkPasswordStrength = (password: string) => {
        const length: boolean = password.length >= 10
        const uppercase: boolean = /[A-Z]/.test(password)
        const lowercase: boolean = /[a-z]/.test(password)
        const number: boolean = /[0-9]/.test(password)
        const special: boolean = /[^a-zA-Z0-9]/.test(password)

        const check = [
            {
                name: "Veillez à avoir 10 caractères minimum",
                value: length
            }, {
                name: "Veillez à avoir au moins une lettre majuscule",
                value: uppercase
            }, {
                name: "Veillez à avoir au moins une lettre minuscule",
                value: lowercase
            }, {
                name: "Veillez à avoir au moins un chiffre",
                value: number
            }, {
                name: "Veillez à avoir au moins un caractère spécial",
                value: special
            }
        ]

        const width: number = check.filter(c => c.value).length / check.length * 100
        const firstCorrection: number = check.findIndex(c => !c.value)
        const text: string | undefined = password.length === 0 ? "Veuillez saisir un mot de passe" : firstCorrection === -1 ? undefined : check[firstCorrection].name

        setIndicator({
            width: width,
            text: text ?? 'Mot de passe robuste',
            color: width === 0 ? '#7D7D7D' : width < 50 ? '#ea3939' : width < 100 ? '#EA9139' : '#46d53f'
        })
    }

    useEffect(() => {
        checkPasswordStrength(password)
    }, [password])

    return (
        <div className="flex flex-col gap-2">
            <div className="w-full h-2 bg-[#D9D9D9] dark:bg-[#7D7D7D] rounded">
                <div 
                    className="h-2 bg-green-600 rounded transition-all duration-500 ease-in-out"
                    style={{
                        width: `${indicator.width}%`,
                        backgroundColor: indicator.color
                    }}
                ></div>
            </div>
            <p className="text-[14px] sm:text-base" style={{
                color: indicator.color
            }}>{ indicator.text }</p>
        </div>
    )
}

export default PasswordStrength;